const burger = () => {
  const burger = document.querySelector(".burger");
  const menuNav = document.querySelector(".menu");
  const overlay = document.querySelector(".overlay");
  const menuList = document.querySelector(".menu__list");

  overlay.addEventListener("click", () => {
    burger.classList.toggle("burger_active");
    menuNav.classList.toggle("visible");
    document.body.classList.remove("body__overflow");
  });

  burger.addEventListener("click", () => {
    burger.classList.toggle("burger_active");
    menuNav.classList.toggle("visible");
    document.body.classList.toggle("body__overflow");
  });

  menuList.addEventListener("click", (e) => {
    if (e.target.classList.contains("menu__link") && !e.target.classList.contains("menu__link-parent")) {
      burger.classList.toggle("burger_active");
      menuNav.classList.toggle("visible");
      document.body.classList.remove("body__overflow");
    }
  });
};

export default burger;
