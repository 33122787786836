import { WOW } from "wowjs";
import "fslightbox";
import "./modules/mask.js";
import "./modules/slider.js";
import modal from "./modules/modal.js";
import form from "./modules/form.js";
import burger from "./modules/burger.js";
import accordion from "./modules/accordion.js";
import menu from "./modules/menu.js";
import scroll from "./modules/scroll.js";
import counter from "./modules/counter.js";
import tabs from "./modules/tabs.js";
import pausevideo from "./modules/pausevideo.js";
import changeDate from "./modules/changeDate.js";
import popup from "./modules/popup.js";
try {
  scroll();
  accordion();
  counter();
  pausevideo();
} catch (e) {}

try {
  menu();
} catch (e) {}

try {
  form();
} catch (e) {}

try {
  tabs();
} catch (e) {}

try {
  new WOW().init();
} catch (e) {}

try {
  popup();
} catch (e) {}

try {
  burger();
} catch (e) {}

try {
  modal();
} catch (e) {}

changeDate();
