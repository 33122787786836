import { showThanksModal } from "./modal.js";
import postFormData from "./postFormData.js";

const form = () => {
  const forms = document.querySelectorAll("form");
  forms.forEach((form) => {
    form.addEventListener("submit", (e) => {
      e.preventDefault();
      postFormData(form, new FormData(form), showThanksModal);
    });
  });
};

export default form;
