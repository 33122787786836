import axios from "axios";

const postFormData = async (form, data, handleResponse) => {
  const button = form.querySelector('button[type="submit"]');
  const spinner = form.querySelector(".submit-spinner");

  try {
    button.disabled = true;
    spinner.classList.remove("submit-spinner_hide");
    await axios.post("mail", data);
    handleResponse("Спасибо", "Скоро мы с вами свяжемся!");
  } catch (e) {
    handleResponse("Что-то пошло не так", "Пожалуйста, повторите попытку позже");
  } finally {
    form.reset();
    button.disabled = false;
    spinner.classList.add("submit-spinner_hide");
  }
};

export default postFormData;
