const pausevideo = () => {
  const nextBtn = document.querySelector(".swiper-button-next");
  const prevBtn = document.querySelector(".swiper-button-prev");
  const videos = document.querySelectorAll(".reviews__slider-video video");

  nextBtn.addEventListener("click", () => {
    videos.forEach((item) => {
      item.pause();
    });
  });

  prevBtn.addEventListener("click", () => {
    videos.forEach((item) => {
      item.pause();
    });
  });
};

export default pausevideo;
