const modalOpen = (selector = "[data-modal]") => {
  const modal = document.querySelector(selector);
  modal.classList.add("modal__open");
  document.body.style.overflow = "hidden";
};

const closeModal = () => {
  const modals = document.querySelectorAll("[data-modal]");
  modals.forEach((modal) => {
    modal.classList.remove("modal__open");
    document.body.style.overflow = "";
  });
};

const onCLickModalTrigger = () => {
  const triggers = document.querySelectorAll("[data-trigger]");
  triggers.forEach((trigger) => {
    trigger.addEventListener("click", (e) => {
      modalOpen();
    });
  });
};

const onClickModal = () => {
  const modals = document.querySelectorAll("[data-modal]");
  modals.forEach((modal) => {
    modal.addEventListener("click", (e) => {
      if (e.target.classList.contains("modal") || e.target.classList.contains("modal__close")) {
        closeModal();
      }
    });
  });
};

const modal = () => {
  onClickModal();
  onCLickModalTrigger();
};

export const showThanksModal = (messageTitle, messageText) => {
  const modal = document.querySelector("[data-modal]");
  const modalbody = document.querySelector(".modal-body");
  const newModal = document.createElement("div");
  modalbody.style.display = "none";
  newModal.classList.add("modal-body");
  newModal.innerHTML = `
    <div class="modal-header">
    <h2 class="modal__title">${messageTitle}</h2>
    <div viewBox="0 0 24 24" class="modal__close">
      &#10006;	
    </div>
  </div>
  <p class="modal__text">${messageText}</p>`;
  modal.append(newModal);
  modalOpen();
  setTimeout(() => {
    closeModal();
    newModal.remove();
    modalbody.style.display = "block";
  }, 1500);
};

export default modal;
export { closeModal };
export { modalOpen };
