const counter = () => {
  const countersBlocks = document.querySelectorAll(".achievement__count");
  const section = document.querySelector("#achievement");
  const options = { threshold: 0.45 };

  const countAchievements = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        countersBlocks.forEach((achievementBlock) => {
          if (!achievementBlock.classList.contains("viz")) {
            let achievementBlockMaxSum = achievementBlock.getAttribute("data-num");
            let achievementSum = 1;
            const time = 1;
            const step = (1000 * time) / achievementBlockMaxSum;
            achievementBlock.classList.add("viz");
            const incAchievement = setInterval(() => {
              if (achievementSum <= achievementBlockMaxSum) {
                achievementBlock.textContent = achievementSum;
              } else {
                clearInterval(incAchievement);
              }
              achievementSum++;
            }, step);
          }
        });
      }
    });
  };

  const observer = new IntersectionObserver(countAchievements, options);
  observer.observe(section);
};

export default counter;
