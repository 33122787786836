function tabs() {
  function tab(tabsParentSelector, tabsSelector, tabsContentSelector) {
    const tabsParent = document.querySelector(tabsParentSelector);
    const tabs = document.querySelectorAll(tabsSelector);
    const tabsContent = document.querySelectorAll(tabsContentSelector);

    function hideTabs() {
      tabs.forEach((item) => {
        item.classList.remove("portfolio__tab_active");
      });

      tabsContent.forEach((item) => {
        item.classList.remove("portfolio__content-key_active");
      });
    }

    function showTab(i = 0) {
      tabs[i].classList.add("portfolio__tab_active");
      tabsContent[i].classList.add("portfolio__content-key_active");
    }

    hideTabs();
    showTab();

    tabsParent.addEventListener("click", (e) => {
      tabs.forEach((item, i) => {
        if (e.target == item) {
          hideTabs();
          showTab(i);
        }
      });
    });
  }

  tab(".portfolio__tabs", ".portfolio__tab", ".portfolio__content-key");
}

export default tabs;
