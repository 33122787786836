const menu = () => {
  const arrow = document.querySelectorAll(".arrow");

  for (let i = 0; i < arrow.length; i++) {
    let currentLink = arrow[i].previousElementSibling;
    let subMenu = arrow[i].nextElementSibling;
    let currentArrow = arrow[i];
    currentLink.classList.add("parent");
    arrow[i].addEventListener("click", () => {
      subMenu.classList.toggle("open");
      currentArrow.classList.toggle("active__arrow");
    });
    currentLink.addEventListener("click", (e) => {
      subMenu.classList.toggle("open");
      currentArrow.classList.toggle("active__arrow");
    });
  }
};

export default menu();
