const accordion = () => {
  const accordions = document.querySelectorAll(".prices__accordion");

  accordions.forEach((elem) => {
    elem.addEventListener("click", () => {
      let content = elem.nextElementSibling;
      if (content.style.maxHeight) {
        content.style.maxHeight = null;
      } else {
        content.style.maxHeight = content.scrollHeight + "px";
      }
    });
  });
};

export default accordion;
