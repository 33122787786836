import Swiper, { Navigation, Pagination, EffectFlip } from "swiper";

try {
  new Swiper(".swiper", {
    modules: [Navigation, Pagination, EffectFlip],
    loop: true,

    pagination: {
      el: ".swiper-pagination",
      type: "fraction",
    },

    centeredSlides: true,

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },

    effect: "flip",
    flipEffect: {
      slideShadow: true,
      limitRotation: true,
    },
  });
} catch (e) {}
