import IMask from "imask";

try {
  document.querySelectorAll('input[type="tel"]').forEach((item) => {
    let phoneMask = IMask(item, {
      mask: "+{38}(000)000-00-00",
    });
  });

  document.querySelectorAll('input[type="text"]').forEach((input) => {
    input.addEventListener("input", (e) => {
      input.value = input.value.replace(/[0-9]/g, "");
      console.log(input.value);
    });
  });
} catch (e) {}
